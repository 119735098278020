import { useRuntimeConfig } from "#imports";

export default function getImage(imageId: number | string | null = null, extension: string | null = null) {
  const baseUrl = useRuntimeConfig().public.baseApiUrl;

  if (imageId === null) {
    return null
  }

  return `${baseUrl}/storage/image/${imageId}${extension ? '.' + extension : ''}`
}
